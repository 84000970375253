import React, {useState, useEffect} from "react";

const HeatingCheck = () => {
    return (
        <div>

        </div>
    );
};

export default HeatingCheck;
